<template>
  <div class="main">
    <Dialog ref="dialog" :config="config" />
    <InFormDetail ref="InFormDetail" class="dia" />
    <nav>
      <span>{{ $fanyi("全部通知") }}</span>
      <div class="choiseStatus">
        <div class="option" @click="setradio(2)" :class="{ active: radio == 2 }">
          <div class="icon"></div>
          {{ $fanyi("已读") }}
        </div>
        <div class="option" @click="setradio(1)" :class="{ active: radio == 1 }">
          <div class="icon"></div>
          {{ $fanyi("未读") }}
        </div>
      </div>
    </nav>

    <!-- 通知列表 -->
    <div class="Notification" v-for="(inFoItem, inFoIndex) in List" :class="{ read: inFoItem.statusName == '已读' }"
      :key="inFoIndex">
      <el-checkbox v-model="inFoItem.checked"></el-checkbox>
      <div class="circle"></div>
      <p class="NotificationTitle" @click="openDetail(inFoItem)">
        {{ inFoItem.title }}
      </p>
    </div>

    <div class="operation">
      <el-checkbox v-model="checked" @change="allChiose">{{
        $fanyi("全选")
      }}</el-checkbox>
      <button class="deleteBtn" @click="deleteNotification">
        <i class="el-icon-delete"></i>{{ $fanyi("删除") }}
      </button>
    </div>
  </div>
</template>
<script>
import Dialog from "../../../../components/public/Dialog";
import InFormDetail from "./InFormDetail";
export default {
  data() {
    return {
      radio: "",
      reradio: "",
      checked: false,
      readItem: {},
      List: [],
      config: {
        top: "20vh",
        width: "500px",
        txtcenter: true,
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("删除"), this.$fanyi("取消")],
      },
    };
  },
  components: { Dialog, InFormDetail },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      this.$api.noticeClientNoticeList({ hasRead: this.radio }).then((res) => {
        let beenRead = [];
        // res.data.data.forEach((item) => {
        //   item.checked = false;
        //   if(item.statusName=='已读'){

        //   }
        // });
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.checked = false;

          if (item.statusName == "已读") {
            beenRead.push(res.data.data.splice(i, 1)[0]);
            i--;
          }
        }
        this.List = [...res.data.data, ...beenRead];

      });
    },
    // 删除通知
    deleteNotification() {
      this.$refs.dialog.open(
        this.$fanyi("是否确认删除选中的通知"),
        () => {
          this.deleteInFo();
        },
        () => { }
      );
    },
    //删除选中通知
    deleteInFo() {
      let deleteArr = [];
      this.List.forEach((item) => {
        if (item.checked == true) {
          deleteArr.push(item.id);
        }
      });
      this.$api.noticeBatchDelete({ ids: deleteArr }).then((res) => {

        if (res.code != 0) return this.$message.error(this.$fanyi("暂无消息通知"));
        this.$message.success(this.$fanyi("操作成功"));
        this.getList();
      });
    },
    // 标记已读
    beenRead(id) {
      this.$api.noticeReadNotice({ id: id }).then((res) => {
        this.getList();
      });
    },
    // 打开详情弹窗
    openDetail(item) {
      if (item.statusName != "已读") {
        this.beenRead(item.id);
      }
      this.readItem = item;
      this.$refs.InFormDetail.open();
    },
    // 设置列表状态筛选
    setradio(st) {
      if (this.radio == st) {
        this.radio = "";
      } else {
        this.radio = st;
      }

      this.getList();
    },
    // 全选
    allChiose() {
      this.List.forEach((item) => {
        item.checked = this.checked;
      });
    },
  },
};
</script>
<style lang='scss' scoped='scoped'>
@import "../../../../css/mixin.scss";

.dia {
  display: none;
}

/deep/.el-checkbox__input {
  height: 20px;

  input {
    width: 20px;
    height: 20px;
  }

  .el-checkbox__inner {
    width: 20px;
    height: 20px;

    &::after {
      left: 7px;
      top: 4px;
    }
  }
}

.main {
  min-height: 726px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  padding: 20px 20px 76px;
  position: relative;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: #f0f0f0;
    padding: 0 20px;

    * {
      font-size: 14px;
    }

    .choiseStatus {
      display: flex;
      align-items: center;
      user-select: none;

      .option {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        margin-left: 40px;

        &.active {
          .icon {
            border: solid 5px $homePageSubjectColor;
          }
        }

        .icon {
          border: solid 1px #888888;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
    }
  }

  .Notification {
    height: 60px;
    border-bottom: #ededed solid 1px;
    display: flex;
    align-items: center;
    padding: 0 20px 0 16px;

    &.read {
      .circle {
        background: #cdcdcd;
      }

      .NotificationTitle {
        color: #cdcdcd;
      }
    }

    /deep/.el-checkbox__input {
      height: 20px;

      input {
        width: 20px;
        height: 20px;
      }

      .el-checkbox__inner {
        width: 20px;
        height: 20px;

        &::after {
          left: 7px;
          top: 4px;
        }
      }
    }

    .circle {
      width: 8px;
      height: 8px;
      background: #ff0000;
      border-radius: 50%;
      margin: 0 6px 0 20px;
    }

    .NotificationTitle {
      font-size: 14px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: rgba($color: $homePageSubjectColor, $alpha: 0.7);
        text-decoration: underline;
      }
    }
  }
}

.operation {
  position: absolute;
  bottom: 0;
  padding: 0 20px 0 17px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 1010px;
  background: #f0f0f0;
  left: 0;

  .deleteBtn {
    color: #182280;

    i {
      margin-right: 6px;
    }
  }
}
</style>