<template>
  <div>
    <Dialog ref="dialog" :config="config">
      <div class="InformBounced ql-editor">
        <h1>{{ item.title }}</h1>
        <p class="timeBox">{{ item.created_at }}</p>
        <div class="con" v-html="item.content"></div>
      </div>
    </Dialog>
  </div>
</template>
<script>
import Dialog from '../../../../components/public/Dialog'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  data() {
    return {
      config: {
        top: '20vh',
        width: '1200px',
        title: '',
        btnTxt: [this.$fanyi('关闭')],
      },
      informList: [],
    }
  },
  components: { Dialog },
  computed: {
    item() {
      return this.$parent.readItem
    },
  },
  mounted() { },
  methods: {
    open() {
      this.$refs.dialog.open(
        '',
        () => { },
        () => {

        }
      )
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
.InformBounced {
  background: #fafafa;
  margin: 0 auto;
  border-radius: 10px;
  width: 1120px;
  height: 500px;
  padding: 20px 40px 40px;
  background-color: #fafafa;
  overflow: auto;

  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;

    margin-bottom: 10px;
    text-align: center;
  }

  .timeBox {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  .con {
    height: 20px;
    font-size: 14px;
  }
}

// /deep/.el-dialog {
//   margin-top: 10vh !important;
// }
/deep/.el-dialog__body {
  padding: 0 30px !important;
}</style>
